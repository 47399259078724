import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import ReactMarkdown from "react-markdown"
import { useNavigate, useParams } from "react-router-dom"
import rehypeKatex from "rehype-katex"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import remarkMath from "remark-math"

import { SvgIcon } from "@mui/material"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/system"

import { ReactComponent as ArrowLeftwards } from "@/assets/images/arrow_leftwards.svg"
import { ReactComponent as ArrowRightwards } from "@/assets/images/arrow_rightwards.svg"

const Container = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  paddingTop: "6rem",
  maxWidth: "100rem",
  margin: "0 auto",
  "& .MuiTabPanel-root": {
    padding: "0",
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: "2rem",
  },
}))

const BlogContainer = styled(Box)(({ theme }) => ({
  maxWidth: "80rem",
  margin: "6rem auto",
  [theme.breakpoints.down("sm")]: {
    margin: "4rem auto",
  },
}))

const BlogTitle = styled(Box)(({ theme }) => ({
  color: "#101010",
  fontSize: "4rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "50px",
  letterSpacing: "0.4px",
  marginBottom: "1.6rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "3.2rem",
    lineHeight: "4rem",
  },
}))

const BlogInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "4rem",
}))

const BlogAuthor = styled(Box)(({ theme }) => ({
  fontSize: "1.8rem",
  fontWeight: 600,
  lineHeight: "2.4rem",
  letterSpacing: "0.18px",
}))

const BlogDate = styled(Box)(({ theme }) => ({
  fontSize: "1.8rem",
  lineHeight: "2.4rem",
  letterSpacing: "0.18px",
}))

const Avatar = styled("img")(({ theme }) => ({
  width: "4.8rem",
  height: "4.8rem",
  borderRadius: "50%",
  marginRight: "0.8rem",
}))

const ArrowButton = styled(Typography)(({ theme }) => ({
  fontSize: "1.8rem",
  fontWeight: 600,
  cursor: "pointer",
}))

const BlogDetail = () => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate("/")
  }

  const { blogId } = useParams()
  const [blogData, setBlogData] = useState({
    id: "",
    title: "",
    content: "",
    date: "",
    author: "",
    avatar: "",
    nextblog: "",
    previousblog: "",
    canonical: "",
  })

  useEffect(() => {
    // 根据blogId加载对应的JSON数据
    const fetchData = async () => {
      try {
        const response = await fetch(`/posts/${blogId}.json`) // 假设JSON数据文件存储在/data文件夹中
        const jsonData = await response.json()
        setBlogData(jsonData)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [blogId])

  return (
    <Container>
      <Helmet>
        <title>{blogData.title} - Scroll</title>
        {/* <meta name="description" content={blogData.summary} /> */}
        <meta property="og:title" content={blogData.title + " - Scroll"} />
        {/* <meta property="og:description" content={blogData.summary} /> */}
        <meta property="og:image" content="https://yezhang.io/og_scroll.png" />
        <meta property="og:url" content={blogData.canonical || `https://yezhang.io/blog/${blogData.id}`} />
        <meta name="twitter:title" content={blogData.title + " - Scroll"} />
        {/* <meta name="twitter:description" content={blogData.summary} /> */}
        <meta name="twitter:image" content="https://yezhang.io/og_scroll.png" />
        {blogData.canonical && <link rel="canonical" href={blogData.canonical} />}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/katex@0.13.13/dist/katex.min.css"
          integrity="sha384-RZU/ijkSsFbcmivfdRBQDtwuwVqK7GMOw6IMvKyeWL2K5UAlyp6WonmB8m7Jd0Hn"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/github-markdown-css/2.9.0/github-markdown.min.css"
          crossOrigin="anonymous"
        />
      </Helmet>
      <SvgIcon
        component={ArrowLeftwards}
        inheritViewBox
        onClick={handleGoBack}
        sx={{
          fontSize: ["2.4rem", "2.8rem"],
          color: "#101010",
        }}
      />
      <BlogContainer>
        <BlogTitle>{blogData.title}</BlogTitle>
        <BlogInfo>
          <Avatar src={blogData.avatar || "/imgs/avatar/yezhang.jpeg"} alt="avatar" />
          <Box>
            <BlogAuthor>{blogData.author || "Ye Zhang"}</BlogAuthor>
            <BlogDate>Posted on {blogData.date}</BlogDate>
          </Box>
        </BlogInfo>
        <ReactMarkdown
          children={blogData.content as string}
          remarkPlugins={[remarkMath, remarkGfm]}
          rehypePlugins={[rehypeKatex, rehypeRaw]}
          className="markdown-body"
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", margin: ["4rem 0", "8rem 0"] }}>
          <ArrowButton className={blogData.previousblog ? "" : "invisible"} onClick={() => navigate(`/blog/${blogData.previousblog}`)}>
            <SvgIcon
              component={ArrowLeftwards}
              inheritViewBox
              sx={{
                fontSize: "18px",
                color: "#101010",
                marginRight: "0.8rem",
              }}
            />
            Previous Blog
          </ArrowButton>
          <ArrowButton className={blogData.nextblog ? "" : "invisible"} onClick={() => navigate(`/blog/${blogData.nextblog}`)}>
            Next Blog
            <SvgIcon
              component={ArrowRightwards}
              inheritViewBox
              sx={{
                fontSize: "18px",
                color: "#101010",
                marginLeft: "0.8rem",
              }}
            />
          </ArrowButton>
        </Box>
      </BlogContainer>
    </Container>
  )
}

export default BlogDetail
