import React from "react"
import { HelmetProvider } from "react-helmet-async"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"

import ScrollToTop from "@/hooks/useScrollToTop"

import Blog from "./pages/blog"
import Landingpage from "./pages/landingpage"

const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    )
  } else return null
}

function App() {
  return (
    <HelmetProvider>
      <RemoveTrailingSlash />
      <ScrollToTop>
        <React.Suspense>
          <Routes>
            <Route path="/" element={<Landingpage />} />
            <Route path="blog/:blogId" element={<Blog />} />
          </Routes>
        </React.Suspense>
      </ScrollToTop>
    </HelmetProvider>
  )
}

export default App
