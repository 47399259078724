import { useState } from "react"

import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import { styled } from "@mui/system"

import About from "./about"
import Footer from "./components/footer"
import Header from "./components/header"
import Home from "./home"

const Container = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  paddingTop: "4rem",
  maxWidth: "76.8rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiTabPanel-root": {
    padding: 0,
  },
}))

const StyledTabList = styled(TabList)(({ theme }) => ({
  width: "100%",

  "& .Mui-selected": {
    color: "#101010",
  },

  "& .MuiTabs-flexContainer": {
    borderBottom: "1px solid #101010",
  },

  "& .MuiTabs-indicator": {
    height: "4px",
    backgroundColor: "#101010",
  },
  "& .MuiTab-root": {
    color: "#101010",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    letterSpacing: "0.2px",
    textTransform: "capitalize",
  },
}))

const LandingPage = () => {
  const [txType, setTxType] = useState("Home")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTxType(newValue)
  }

  return (
    <Container>
      <Header />
      <TabContext value={txType}>
        <StyledTabList onChange={handleChange} textColor="primary" variant="fullWidth">
          <Tab label="Home" value="Home"></Tab>
          <Tab label="About" value="About"></Tab>
        </StyledTabList>
        <TabPanel value="Home">
          <Home />
        </TabPanel>
        <TabPanel value="About">
          <About />
        </TabPanel>
      </TabContext>
      <Footer />
    </Container>
  )
}

export default LandingPage
