import { useNavigate } from "react-router-dom"

import { Box } from "@mui/material"
import { SvgIcon } from "@mui/material"
import { styled } from "@mui/system"

import BLOGS from "@/assets/blog-directory.json"
import { ReactComponent as ArrowRightwards } from "@/assets/images/arrow_rightwards.svg"

const Container = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  paddingTop: "4rem",
  maxWidth: "76.8rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "1.6rem",
  },
}))

const BlogItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "3.2rem 0",
  borderBottom: "1px solid #101010",
  cursor: "pointer",
  "&:last-child": {
    borderBottom: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "1.6rem 0",
  },
}))

const BlogTitle = styled(Box)(({ theme }) => ({
  color: "#101010",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "36px",
  letterSpacing: "0.24px",
  marginBottom: "8px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "6px",
  },
}))

const BlogDate = styled(Box)(({ theme }) => ({
  color: "#101010",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "28px",
  letterSpacing: "0.2px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
}))

const Home = () => {
  const navigate = useNavigate()
  const handleClick = blog => {
    navigate("/blog/" + blog.id)
  }
  return (
    <Container>
      {BLOGS.map(blog => {
        return (
          <BlogItem key={blog.id} onClick={() => handleClick(blog)}>
            <div>
              <BlogTitle>{blog.title}</BlogTitle>
              <BlogDate>{blog.date}</BlogDate>
            </div>
            <SvgIcon
              component={ArrowRightwards}
              inheritViewBox
              sx={{
                fontSize: ["2.4rem", "2.8rem"],
                color: "#101010",
              }}
            />
          </BlogItem>
        )
      })}
    </Container>
  )
}

export default Home
