import { Typography } from "@mui/material"
import { styled } from "@mui/system"

const Avatar = styled("img")(({ theme }) => ({
  width: "120px",
  height: "120px",
  borderRadius: "50%",
  margin: "24px auto",
  display: "block",
  [theme.breakpoints.down("sm")]: {
    width: "8rem",
    height: "8rem",
    margin: "1.2rem auto",
  },
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "4rem",
  fontWeight: "500",
  textAlign: "center",
  color: "#101010",
  marginBottom: "2.4rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "3.2rem",
    marginBottom: "1.8rem",
  },
}))

const Header = () => {
  return (
    <div className="header">
      <div className="header__logo">
        <Avatar src="/imgs/avatar/yezhang.jpeg" alt="avatar" />
      </div>
      <Title>Ye Zhang’s blog</Title>
    </div>
  )
}

export default Header
