import { Box, Typography } from "@mui/material"
import { SvgIcon } from "@mui/material"
import { styled } from "@mui/system"

import { ReactComponent as EmailSvg } from "@/assets/images/media/email.svg"
import { ReactComponent as LinkedInSvg } from "@/assets/images/media/linkedin.svg"
import { ReactComponent as ScrollSvg } from "@/assets/images/media/scroll.svg"
import { ReactComponent as TwitterSvg } from "@/assets/images/media/twitter.svg"

const Container = styled(Box)(({ theme }) => ({
  marginBottom: "6rem",
  marginTop: "15rem",
}))

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  textAlign: "center",
  color: "#101010",
  letterSpacing: "0.2px",
  fontSize: "20px",
  lineHeight: "24px",
  marginBottom: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "20px",
  },
}))

const MediaLinks = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  margin: "0 auto",
  gap: "4rem",
  [theme.breakpoints.down("sm")]: {},
}))

const mediaLinks = [
  {
    name: "Twitter",
    link: "https://twitter.com/yezhang1998",
    icon: TwitterSvg,
  },
  {
    name: "Scroll",
    link: "https://scroll.io",
    icon: ScrollSvg,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ye-zhang-70b072148",
    icon: LinkedInSvg,
  },
  {
    name: "Email",
    link: "mailto:zhangye1998@pku.edu.cn",
    icon: EmailSvg,
  },
]

const Footer = () => {
  return (
    <Container>
      <Title>Thanks for stopping by! You can also find me on:</Title>
      <MediaLinks>
        {mediaLinks.map((mediaLink, index) => (
          <div key={index}>
            <a href={mediaLink.link}>
              <SvgIcon
                component={mediaLink.icon}
                inheritViewBox
                sx={{
                  fontSize: ["40px", "64px"],
                  color: "#101010",
                  "&:hover": {
                    color: "#2F80ED",
                  },
                }}
              />
            </a>
          </div>
        ))}
      </MediaLinks>
    </Container>
  )
}

export default Footer
