import { Box, Typography } from "@mui/material"
import { styled } from "@mui/system"

const Container = styled(Box)(({ theme }) => ({
  paddingTop: "3.2rem",
}))
const Content = styled(Typography)(({ theme }) => ({
  color: "#101010",
  fontFamily: "var(--developer-page-font-family)",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "28px",
  letterSpacing: "0.18px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
}))

const About = () => {
  return (
    <Container>
      <Content>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie, lacus et elementum eleifend, lacus enim feugiat risus, ut molestie
        nisi odio a tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus vel ex lectus. Praesent
        aliquet sagittis urna. Integer convallis arcu eu euismod pellentesque. Pellentesque dapibus interdum dui, ut malesuada risus efficitur in.
        Aliquam erat volutpat. Maecenas eleifend sollicitudin mauris. Proin non neque iaculis, scelerisque orci eget, ultricies mi. Sed sollicitudin,
        leo eget bibendum ultrices, nulla eros fringilla dui, nec lobortis lorem mi imperdiet leo. Cras non tempor eros. Nunc vestibulum dolor a
        lectus porta, sed vehicula nisi hendrerit. Quisque sit amet orci eu elit posuere blandit sit amet id erat. Morbi diam lorem, luctus at congue
        eu, ultrices id erat. Mauris pretium lacus ac tortor fermentum blandit eu quis est. Integer orci nibh, elementum vehicula malesuada ut,
        elementum vitae augue.
      </Content>
      <br />
      <Content>
        In tempus dignissim sem, eu posuere quam varius a. Ut in dignissim tortor, nec mollis nibh. Etiam nec turpis feugiat, eleifend orci id,
        aliquam massa. Mauris maximus pulvinar purus, in rutrum nisi auctor sit amet. Vestibulum vehicula libero nec nunc bibendum elementum. Cras
        aliquam finibus neque, et viverra mauris sollicitudin ut. Suspendisse ac sapien eget felis mollis condimentum. Morbi sed neque pellentesque
        felis lobortis dictum non ut diam. Nullam velit lacus, eleifend ut massa dignissim, tempor tincidunt ante.
      </Content>
      <br />

      <Content>
        Fusce justo enim, euismod in arcu vitae, commodo feugiat risus. Sed gravida lorem vitae varius laoreet. Vestibulum aliquet elit eget ipsum
        placerat luctus sit amet sed ipsum. Ut at elit augue. In justo nulla, blandit eget turpis eu, congue interdum mauris. Donec ac dignissim
        turpis. Sed sed nisi blandit, ultricies nunc ut, finibus dui.
      </Content>
      <br />

      <Content>
        Donec in molestie velit, at lobortis odio. Pellentesque varius auctor erat, et rutrum ante molestie eget. Nunc venenatis sit amet augue sed
        malesuada. Aenean tellus augue, feugiat a fermentum eget, tempor nec est. Etiam scelerisque, est a faucibus molestie, mauris elit cursus
        justo, sit amet pretium justo lectus tincidunt metus. Integer orci dui, convallis id mollis ac, rhoncus a odio. Etiam porttitor fringilla
        ligula. Duis porttitor lectus mauris, non suscipit ligula vehicula quis.
      </Content>
    </Container>
  )
}

export default About
